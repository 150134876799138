import { initServiceWorker } from './utils.js';
import { scale2seconds } from './utils.js';

import api from './apiSetup.js';


api.getUserTournaments = async () => {
  const response = await api.get("/tournaments");
  return response.data;
}

api.getTournament = async (tournamentId) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.get(`/tournaments/${tournamentId}`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.createTournament = async (tournamentData, timeSelects) => {
  let data = null
  let error = null

  tournamentData.time_initial = scale2seconds(tournamentData.time_initial, timeSelects.initial);
  tournamentData.time_per_move = scale2seconds(tournamentData.time_per_move, timeSelects.perMove);

  const request = async () => {
    try {
      const response = await api.post(`/tournaments`, {
        tournament: tournamentData,
      });
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.deleteTournament = async (tournamentId) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.delete(`/tournaments/${tournamentId}`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.createParticipant = async (tournamentId, participant) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.post(`/participants`, {
        tournament_id: tournamentId,
        participant: participant,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.joinTournament = async (tournamentId) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.post(`/tournaments/${tournamentId}/join_tournament`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.leaveTournament = async (tournamentId) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.delete(`/tournaments/${tournamentId}/leave_tournament`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.startTournament = async (tournamentId) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.put(`/tournaments/${tournamentId}/start_tournament`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.endTournament = async (tournamentId) => {
  let data = null
  let error = null
  let status = null

  const request = async () => {
    try {
      const response = await api.put(`/tournaments/${tournamentId}/end_tournament`)
      data = response.data;
      status = response.status
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error, status };
}

api.reopenTournament = async (tournamentId) => {
  let data = null
  let error = null
  let status = null

  const request = async () => {
    try {
      const response = await api.put(`/tournaments/${tournamentId}/reopen_tournament`)
      data = response.data;
      status = response.status
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error, status };
}

api.openRegistrations = async (tournamentId) => {
  let data = null;
  let error = null;

  const request = async () => {
    try {
      const response = await api.put(`/tournaments/${tournamentId}/open_registrations`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.closeRegistrations = async (tournamentId) => {
  let data = null;
  let error = null;

  const request = async () => {
    try {
      const response = await api.put(`/tournaments/${tournamentId}/close_registrations`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.createRound = async (tournamentId, params) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.post(`/rounds`, {
          tournament_id: tournamentId,
          status: params.status ? params.status : 'published',
        }
      )
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.publishRound = async (round) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.put(`/rounds/${round.id}/publish`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.deleteRound = async (roundId) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.delete(`/rounds/${roundId}`)
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.updateParticipant = async (participant) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.put(`/participants/${participant.id}`, {
          participant: participant,
        });
      data = response;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.toggleParticipantActivation = async (participant) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.put(`/participants/${participant.id}/toggle_participant_activation`);
      data = response;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.toggleAllParticipantsActivation = async (tournamentId, status) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.put(`/tournaments/${tournamentId}/toggle_all_participants_activation`, {
        activation_status: status,
      });
      data = response;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data: data, error: error };
}

api.deleteParticipant = async (participant) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.delete(`/participants/${participant.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
        }
      });
      data = response.data;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.deleteAllParticipants = async (tournament) => {
  let data = null;
  let error = null;

  const request = async () => {
    try {
      const response = await api.delete(`/tournaments/${tournament.id}/participants/`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
        }
      });
      data = response.data;
    } catch (err) {
      error = err
    }
  }

  await request();

  return { data, error };
}

api.updatePairingResult = async (payload) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.put(`/pairings/${payload.pairingId}`, {
        result: payload.result,
        referee_override: payload.override,
      });
      data = response;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.notifyRemainingPlayers = async (tournamentId) => {
  let data = null
  let error = null

  const request = async () => {
    try {
      const response = await api.post(`/tournaments/${tournamentId}/notify_remaining_players`);
      data = response;
    } catch (err) {
      error = err
    }
  }
  await request();

  return { data, error };
}

api.subscribeWebpush = async (auth_token, participant_id) => {
  const init = await initServiceWorker();

  if (init.webpushSubscription && (auth_token || participant_id)) {
    await api.post('/webpush_subscriptions', {
      'webpush_subscription': init.webpushSubscription,
      'participant_id': participant_id,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`,
      },
    })
    .then(response => {
      return true
    })
    .catch(err => {
      console.error(err);
      return false
    })
  } else {
    console.warn("Webpush not initialized or not subscription not legitimate");
  }
}

api.unsubscribeWebpush = async (subscription, participant_id) => {
  await api.delete('/delete_webpush_subscription', {
    data: {
      'webpush_subscription': subscription,
      'participant_id': participant_id,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    return true
  })
  .catch(err => {
    console.error(err);
    return false
  })
}

export default api;
